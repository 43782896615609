<template>
    <div class="sms_comp">
        <h3 class="sub_heading2">SMS Content</h3>
        <div class="form_grp" v-if="hasNumberField">
            <div class="group_item">
                <label class="input_label">Number</label>
                <div class="field_wpr" :class="{ 'has-error': hasNumberError }">
                    <input type="text" v-model="contactNumber" @focusout="validate(1)" />
                </div>
                <span class="text-danger" v-show="hasNumberError">{{ numberErrorMessage }}</span>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <div class="input_label">
                    <h3 class="sub_heading2 mb-1">Send SMS</h3>
                    <emoji-picker :onSelectEmoji="insertEmoji" classes="subject-emoji-picker" :ref="`emoji-${smsUuid}`" v-click-outside="hideEmoji" />
                    <div class="dropdown mr-3 dropdown-personalize" @click="smsOption = !smsOption" v-click-outside="hidePersonalize">
                        <i class="fas fa-user-alt"></i>
                        <div class="drp_wrapper" v-if="smsOption" :class="{ active : smsOption }">
                            <ul>
                                <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="dropdown dropdown-personalize" @click="msgOption = !msgOption" v-click-outside="hideOption">
                        <i class="fas fa-ellipsis-v"></i>
                        <div class="drp_wrapper" v-if="msgOption" :class="{active : msgOption}">
                            <ul>
                                <li v-if="hasResetDefault" @click="resetToDefault">Reset to default message</li>
                                <!-- <li @click="templateLibrary = !templateLibrary" :class="{ active : templateLibrary }">Import template</li> -->
                                <li @click="sendTestSMS = !sendTestSMS" :class="{active : sendTestSMS}">Send test SMS</li>
                                <li @click="previewTestSMS = !previewTestSMS" :class="{ active : previewTestSMS }">Preview in browser</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="field_wpr" :class="{ 'has-error': hasTextError }">
                    <textarea :placeholder="placeholder" :id="smsUuid" :name="name" :maxlength="maxLength" v-model="smsText" @focusout="validate(2)"></textarea>
                </div>
                <span class="text-danger" v-show="hasTextError">{{ textErrorMessage }}</span>
                <span class="letter_count">
                    <a class="import-template" @click="toggleTemplateDrawer()">Import Template</a>
                    {{ smsText.length }} / {{ maxLength }}
                </span>
                <send-email-sms-preview v-model="sendTestSMS" :is-preview="previewTestSMS" @closePreview="previewTestSMS = value" :post-params="form" :module="previewModule" :type="2" />
            </div>
        </div>
        <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="sms" />
        <sms-media v-model="smsMedia" />
        <import-template v-model="importTemplate" :handle-import="handleTemplateImport" template-type="sms" />
    </div>
</template>

<script>
    import { uuid } from 'vue-uuid'
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const TemplateLibrary = defineAsyncComponent(() => import('@/components/TemplateLibrary'))
    const SmsMedia = defineAsyncComponent(() => import('@/components/SmsMedia'))
    const SendEmailSmsPreview = defineAsyncComponent(() => import('@/components/SendEmailSmsPreview'))
    const EmojiPicker = defineAsyncComponent(() => import('@/components/EmojiPicker'))
    const ImportTemplate = defineAsyncComponent(() => import('@/components/ImportTemplate'))

    import constants from '@/utils/Constant'
    import Toastr from '@/utils/Toastr'

    export default {
        name: "Sms Component",

        data () {
            return {
                smsUuid: 'sms-'+uuid.v4(),
                phoneAdd: false,
                phone: false,
                smsOption: false,
                msgOption: false,
                templateLibrary: false,
                sendTestSMS: false,
                previewTestSMS: false,
                templateType: '',
                smsAttachment: '',
                smsSchedule: false,
                defaultPersonalize: [],
                schedule: {},
                smsMedia: {
                    is_default: true,
                    has_media: 0,
                    media: this.media,
                },
                hasTextError: false,
                hasNumberError: false,
                textErrorMessage: '',
                numberErrorMessage: '',
                contactNumber: '',
                defaultContent: '',
                form: {},
                importTemplate: false,
            };
        },

        props:{
            name: String,
            modelValue: String,
            number: String,
            contacts: [String, Number],
            placeholder: {
                type: String,
                default: 'Type message',
            },
            personalize: {
                type: [Object, Array],
                default: () => [],
            },
            maxLength: {
                type: Number,
                default: 320,
            },
            source: {
                type: String,
                default: 'Launch Pad',
            },
            mediaFieldName: {
                type: String,
                default: 'media',
            },
            defaultSmsHandler: {
                type: Boolean,
                default: false,
            },
            handleDefaultSms: Function,
            handleUpdate: Function,
            media: [Object, Array],
            hasNumberField: {
                type: Boolean,
                default: false,
            },
            previewModule: {
                type: String,
                default: 'sms',
            },
            moduleId: {
                type: Number,
                default: 0,
            },
            updateSmsText: Function,
            hasResetDefault: {
                type: Boolean,
                default: true,
            },
            updateHasMediaField: Function,
            trackFocusout: Function,
        },

        emits: ['update:modelValue', 'update:number', 'update:media'],

        watch: {
            number (number) {
                const vm = this;

                vm.contactNumber = number;
            },

            contactNumber (number) {
                const vm = this;

                if (vm.handleUpdate) {
                    vm.handleUpdate({ number });
                }

                vm.$emit('update:number', number);
            },

            sendTestSMS (val) {
                if (val) {
                    const vm = this;

                    setTimeout(function () {
                        vm.setSMSContent();
                    }, 10);
                }
            },

            previewTestSMS (val) {
                if (val) {
                    const vm = this;

                    setTimeout(function () {
                        vm.setSMSContent();
                    }, 10);
                }
            },

            smsMedia: {
                handler (smsMedia) {
                    const vm = this;

                    if (vm.$parent[vm.mediaFieldName]) {
                        vm.$parent[vm.mediaFieldName] = smsMedia.media;
                    } else if (vm.$parent.$parent[vm.mediaFieldName]) {
                        vm.$parent.$parent[vm.mediaFieldName] = smsMedia.media;
                    } else if (vm.$parent.$parent.$parent[vm.mediaFieldName]) {
                        vm.$parent.$parent.$parent[vm.mediaFieldName] = smsMedia.media;
                    } else if (vm.$parent.$parent.$parent.$parent[vm.mediaFieldName]) {
                        vm.$parent.$parent.$parent.$parent[vm.mediaFieldName] = smsMedia.media;
                    }

                    if (vm.updateSmsText) {
                        vm.updateSmsText();
                    }

                    if (vm.updateHasMediaField) {
                        vm.updateHasMediaField(smsMedia.has_media);
                    }
                },
                deep: true
            },

            'smsMedia.has_media' (val) {
                const vm = this;

                setTimeout(() => {
                    if (vm.trackFocusout) {
                        vm.trackFocusout('has_media');
                    }
                }, 1000)
            },

            'smsMedia.media' (val) {
                const vm = this;

                setTimeout(() => {
                    if (vm.trackFocusout) {
                        vm.trackFocusout('media');
                    }
                }, 1000)
            },
        },

        components:{
            TemplateLibrary,
            SmsMedia,
            SendEmailSmsPreview,
            EmojiPicker,
            ImportTemplate,
        },

        computed: {
            smsText: {
                get () {
                    return this.modelValue ? this.modelValue : '';
                },
                set (sms) {
                    this.$emit('update:modelValue', sms);
                }
            },
        },

        mounted () {
            const vm = this;

            vm.smsText = vm.modelValue;
            vm.defaultContent = vm.modelValue;
            vm.contactNumber = vm.number;
            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);

            if (vm.media && Object.values(vm.media).length) {
                vm.smsMedia = {
                                  is_default: true,
                                  has_media: 1,
                                  media: vm.media,
                              };
            }
        },

        methods: {
            ...mapActions({
                sendSMS: 'contactModule/sendSMS',
                scheduleSMS: 'smsCenterModule/scheduleSMS',
            }),

            insertEmoji (emoji) {
                const vm = this;
                const textarea    = document.getElementById(vm.smsUuid);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = emoji;

                vm.smsText = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            insertPersonalize (personalize) {
                const vm = this;
                const textarea    = document.getElementById(vm.smsUuid);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.smsText = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            resetToDefault () {
                const vm = this;

                if (vm.defaultSmsHandler) {
                    vm.handleDefaultSms();
                } else if (vm.defaultContent) {
                    vm.smsText = vm.defaultContent;
                } else {
                    vm.smsText = '';
                }
            },

            handleTemplateImport (template) {
                const vm = this;

                vm.smsText = template.sms_content;

                if (template.sms_media) {
                    vm.smsMedia.is_default  = true;
                    vm.smsMedia.has_media   = 1;
                    vm.smsMedia.media       = [template.sms_media];
                } else {
                    vm.smsMedia.is_default  = false;
                    vm.smsMedia.has_media   = 0;
                    vm.smsMedia.media       = [];
                }

                setTimeout(function () {
                    vm.smsMedia.is_default  = false;
                }, 500);
            },

            validate (type = 0) {
                const vm = this;
                let isValid = true;

                vm.hasNumberError     = false;
                vm.hasTextError       = false;
                vm.numberErrorMessage = '';
                vm.textErrorMessage   = '';

                // if (!vm.contactNumber && (type == 0 || type == 1)) {
                //     isValid = false;
                //
                //     vm.hasNumberError     = true;
                //     vm.numberErrorMessage = 'The contact number field is required.'
                // }

                if (!vm.smsText && (type == 0 || type == 2)) {
                    isValid = false;

                    vm.hasTextError     = true;
                    vm.textErrorMessage = 'The sms field is required.'
                } else if(vm.smsText.length > 320 && (type == 0 || type == 2)) {
                    isValid = false;

                    vm.hasTextError     = true;
                    vm.textErrorMessage = 'The sms character must be less than or equal to 320.'
                }

                if (isValid) {
                    setTimeout(() => {
                        if (vm.trackFocusout) {
                            vm.trackFocusout('content');
                        }
                    }, 1000)
                }

                return isValid;
            },

            handleSMSSend () {
                const vm = this;

                if (vm.validate()) {
                    const params = {
                        media: vm.smsMedia.media,
                        sms: vm.smsText,
                        contact_number: vm.contactNumber,
                        contact_id: vm.contacts,
                        source: vm.source,
                    };

                    return vm.sendSMS(params);
                }
            },

            setSMSContent () {
                const vm = this;

                if (vm.validate()) {
                    vm.form = {
                        media: vm.smsMedia.media,
                        content: vm.smsText,
                        contact_number: vm.contactNumber,
                        source: vm.source,
                        module_id: vm.moduleId,
                    };
                }
            },

            hideEmoji () {
                const vm = this;
                const emoji = vm.$refs[`emoji-${vm.smsUuid}`];

                if (emoji && emoji.toggle) {
                    emoji.toggle = false;
                }
            },

            hidePersonalize () {
                const vm = this;

                vm.smsOption = false;
            },

            hideOption () {
                const vm = this;

                vm.msgOption = false;
            },

            toggleTemplateDrawer () {
                const vm = this;

                vm.importTemplate = true;
            },
        },
    }
</script>

<style scoped>
    .sms_comp{
        padding: 20px 30px;
        border-radius: 5px;
        margin-bottom: 15px;
        background: #fff;
    }
    .modal .sms_comp{
        padding: 15px 20px;
    }
    :deep(.v3-input-emoji-picker) {
        margin: 0 0 0 auto;
    }

    :deep(.subject-emoji-picker .custom-emoji-picker) {
        top: 0;
    }

    :deep(.v3-input-picker-icon) {
        height: 0;
        margin-right: 5px;
    }

    :deep(.emoji-picker.subject-emoji-picker) {
        margin: 0 0 0 auto;
    }

    .input_label {
        position: relative;
    }

    :deep(.subject-emoji-picker) {
        position: absolute;
        right: 0;
    }

    :deep(.subject-emoji-picker .dropdown-toggle) {
        margin-right: 40px;
    }

    .dropdown-personalize {
        position: absolute;
        right: 0;
    }

    .letter_count a.import-template {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: pointer;
    }
</style>
